import styled from "styled-components";

export default styled.div`
  display: flex;
 
 height:17rem;
  width: 15rem;
  color: #fff;
  margin: 0 5px;
  font-size: 4em;
`;