import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './Home';

function App() {
  return (
    <div className='App'>
      <Home />
    </div>
  )
}

export default App